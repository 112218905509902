:root {
  /* Spacing */
  --p-container: 1rem;
  /* Colors */
  --c-primary: #001e3c;
  --c-secondary: #fff;
  /* Typography */
  --ff-nunito: 'Nunito', sans-serif;
  --ff-poppins: 'Poppins', sans-serif;
  --ff-primary: 'Poppins', sans-serif;
  --ff-secondary: 'Nunito', sans-serif;

  --fw-light: lighter;
  --fw-normal: normal;
  --fw-bold: bold;
  --fw-3: 300;
  --fw-6: 600;
  --fw-9: 900;
  /* Utilities */
  --br-circle: 50%;
  --br-1: 10px;
  --br-2: 20px;
  --br-3: 30px;
}
