.form {
  max-width: 400px;
  width: 100%;
  height: 70vh;
  /*  width: 400px; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 2rem;
  margin: 0 auto;
}

.inputs {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.5rem;
  width: 100%;
}
