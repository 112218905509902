.container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 0.2rem;
  margin-top: 4rem;
  width: 100%;
  /* height: 100%; */
}

.item {
  border: 2px solid var(--c-primary);
  list-style: none;
  position: relative;
  transition: background-color 0.2s ease;
  width: 100%;
}

.item a {
  color: #fff;
  display: flex;
  position: relative;
  text-decoration: none;
  width: 100%;
}

.item a .icon {
  display: block;
  height: 50px;
  line-height: 60px;
  min-width: 50px;
  position: relative;
  text-align: center;
  margin: 0 10px;
}

.item a .title {
  display: block;
  height: 50px;
  line-height: 50px;
  padding: 0 10px;
  position: relative;
  text-align: start;
  font-weight: bold;
  white-space: nowrap;
}

/* Item active */
.item.active {
  background-color: #fff;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.15);
  color: var(--c-primary);
  transition: background-color 0.5s ease;
}
.item.active a {
  color: var(--c-primary);
}

/* Item hovered */
.item:not(.active):hover {
  border: 2px solid var(--c-secondary);
  transition: background-color 0.5s ease;
}
.item:not(.active):hover a {
  color: var(--c-secondary);
}

@media (max-width: 768px) {
  .container {
    display: none;
  }
}
