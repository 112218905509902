/* Header */
.container {
  align-items: center;
  display: flex;
  gap: 1rem;
  justify-content: flex-end;
}

.mobileMenu {
  margin-right: auto;
  display: none;
}
@media (max-width: 900px) {
  .mobileMenu {
    display: block;
  }
}
