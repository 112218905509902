@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,600;0,700;1,200;1,300;1,700;1,800&family=Poppins:wght@100;200;300;400;500;600;700&display=swap');
@import './variables.module.css';

* {
  box-sizing: border-box;
  border: 0;
  padding: 0;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: var(--ff-primary);
  overflow: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
