.container {
  display: flex;
  padding: 0.5rem;
  height: calc(100vh - 1rem);
  /* overflow-y: auto; */
}

.main {
  flex-grow: 1;
  max-width: 100%;
  overflow-y: auto;
}

.children {
  /* padding: 4rem; */
}
